import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

const notFound = () => {
  return (
    <main className={`main-404`}>
      <Helmet>
        <title>404 · Page not found</title>
      </Helmet>
      <div className="global-section">
        <div className="container text-center">
          <h3>Oops...</h3>
          <h1>Page not found</h1>
          <Link to="/" className={`custom-btn mt-2 mt-lg-3`}>
            Back to Homepage
          </Link>
        </div>
      </div>
    </main>
  )
}

export default notFound
